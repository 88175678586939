/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const actions = {

    setFiltroCadastroOrdemServico({ commit }, payload) { 
      commit('SET_FILTRO_CADASTRO_ORDEM_SERVICO', payload)
    },
    setFiltroCadastroModulos({ commit }, payload) { 
      commit('SET_FILTRO_CADASTRO_MODULOS', payload)
    },
    setFiltroCadastroClientes({ commit }, payload) { 
      commit('SET_FILTRO_CADASTRO_CLIENTES', payload)
    },
    setFiltroCadastroAcessoriosModulos({ commit }, payload) { 
      commit('SET_FILTRO_CADASTRO_ACESSORIOS_MODULOS', payload)
    },
    setFiltroCadastroTecnicos({ commit }, payload) { 
      commit('SET_FILTRO_CADASTRO_TECNICOS', payload)
    },
    setFiltroCadastroUsuarios({ commit }, payload) { 
      commit('SET_FILTRO_CADASTRO_USUARIOS', payload)
    },
    setFiltroLocalizacao({ commit }, payload) { 
      commit('SET_FILTRO_LOCALIZACAO', payload)
    },
    setFiltroRelatorioCliente({ commit }, payload) { 
      commit('SET_FILTRO_RELATORIO_CLIENTE', payload)
    },
    setFiltroRelatorioClienteBackup({ commit }, payload) { 
      commit('SET_FILTRO_RELATORIO_CLIENTE_BACKUP', payload)
    },
    setDadosBackendSubstituicao({ commit }, payload) { 
      commit('SET_DADOS_BACKEND_SUBSTITUICAO', payload)
    },
    setDadosBackendSubstituicaoSimcard({ commit }, payload) { 
      commit('SET_DADOS_BACKEND_SUBSTITUICAO_SIMCARD', payload)
    },
    setDadosBackendAtivacao({ commit }, payload) { 
      commit('SET_DADOS_BACKEND_ATIVACAO', payload)
    },
    setDadosBackendAtivacaoBackup({ commit }, payload) { 
      commit('SET_DADOS_BACKEND_ATIVACAO_BACKUP', payload)
    },
    setDadosBackendAtivacaoAcessorio({ commit }, payload) { 
      commit('SET_DADOS_BACKEND_ATIVACAO_ACESSORIO', payload)
    },
    setFiltroRelatorioVeiculo({ commit }, payload) { 
      commit('SET_FILTRO_RELATORIO_VEICULO', payload)
    },
    setDadosPesquisaOcorencias({ commit }, payload) { 
      commit('SET_DADOS_PESQUISA_OCORRENCIA', payload)
    },
    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },
}

export default actions
