/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {

	// COMPONENT
		// vx-autosuggest
	// starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {

    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return "xl"
    else if (state.windowWidth >= 992) return "lg"
    else if (state.windowWidth >= 768) return "md"
    else if (state.windowWidth >= 576) return "sm"
    else return "xs"
  }, 
  filtroCadastroOrdemServico: state => {
    return state.filtrosArmazenados.cadastroOrdemServico;
  },
  filtroCadastroModulos: state => {
      return state.filtrosArmazenados.cadastroModulos;
  },
  filtroCadastroClientes: state => {
    return state.filtrosArmazenados.cadastroClientes;
  },
  filtroCadastroAcessoriosModulos: state => {
    return state.filtrosArmazenados.cadastroAcessoriosModulos;
  },
  filtroCadastroTecnicos: state => {
    return state.filtrosArmazenados.cadastroTecnicos;
  },
  filtroCadastroUsuarios: state => {
    return state.filtrosArmazenados.cadastroUsuarios;
  },
  filtroLocalizacao: state => {
    return state.filtrosArmazenados.localizacao;
  },
  filtroRelatorioCliente: state => {
    return state.filtrosArmazenados.relatorioCliente;
  },
  filtroRelatorioClienteBackup: state => {
    return state.filtrosArmazenados.relatorioClienteBackup;
  },
  dadosBackendSubstituicao: state => {
    return state.filtrosArmazenados.backendSubstituicao;
  },
  dadosBackendSubstituicaoSimcard: state => {
    return state.filtrosArmazenados.backendSubstituicaoSimcard;
  },
  dadosBackendAtivacao: state => {
    return state.filtrosArmazenados.backendAtivacao;
  },
  dadosBackendAtivacaoBackup: state => {
    return state.filtrosArmazenados.backendAtivacaoBackup;
  },
  dadosBackendAtivacaoAcessorio: state => {
    return state.filtrosArmazenados.backendAtivacaoAcessorio;
  },
  filtroRelatorioVeiculo: state => {
    return state.filtrosArmazenados.relatorioVeiculo;
  },
  filtroPesquisaOcorrencia: state => {
    return state.filtrosArmazenados.pesquisaOcorrencia;
  }
}

export default getters
