/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

          {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
              {
                path: '/',
                redirect: '/login'
              },  
              {
                path: '/login',
                name: 'page-login',
                component: () => import('@/views/Login.vue')
              },
              {
                path: '/login/:nomeCentral',
                name: 'page-login-central',
                component: () => import('@/views/Login.vue'),
                props: true,
                meta: {
                    rule: 'editor'
                }
            },
              {
                path: '/pages/error-404',
                name: 'page-error-404',
                component: () => import('@/views/pages/Error404.vue')
              },
            ]
        },
        {
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
              {
                path: '/home',
                name: 'home',
                component: () => import('./views/Home.vue')
              },
              {
                path: '/cadastro/modulos/pesquisar',
                name: 'cadastro-modulos-pesquisar',
                component: () => import('./views/cadastros/modulos/Modulo.vue'),
                meta: {                       
                  pageTitle: 'Módulos',
                }                
              },
              {
                path: '/cadastro/modulos/editar',
                name: 'cadastro-modulos-editar',
                component: () => import('./views/cadastros/modulos/ModuloEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Módulo',
                }
              },
              {
                path: '/cadastro/modulos/importar',
                name: 'cadastro-modulos-importar',
                component: () => import('./views/cadastros/modulos/ImportarModulos.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Importar Modulos',
                }
              },
              {
                path: '/cadastro/clientes/pesquisar',
                name: 'cadastro-clientes-pesquisar',
                component: () => import('./views/cadastros/clientes/Cliente.vue'),
                meta: {                       
                  pageTitle: 'Clientes',
                }
              },
              {
                path: '/cadastro/clientes/editar',
                name: 'cadastro-clientes-editar',
                component: () => import('./views/cadastros/clientes/ClienteEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Cliente',
                }
              },

              {
                path: '/cadastro/pre-cadastro/pesquisar',
                name: 'cadastro-pre-cadastro-pesquisar',
                component: () => import('./views/cadastros/pre-cadastro/PreCadastro.vue'),
                meta: {                       
                  pageTitle: 'Pré Cadastros',
                }
              },
              {
                path: '/cadastro/pre-cadastro/editar',
                name: 'cadastro-pre-cadastro-editar',
                component: () => import('./views/cadastros/pre-cadastro/PreCadastroEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Pré Cadastro',
                }
              },

              {
                path: '/cadastro/ocorrencia-veiculo/pesquisar',
                name: 'cadastro-ocorrencia-veiculo-pesquisar',
                component: () => import('./views/cadastros/ocorrencias-veiculos/Ocorrencia.vue'),
                meta: {                       
                  pageTitle: 'Ocorrências',
                }
              },
              {
                path: '/cadastro/ocorrencia-veiculos/editar',
                name: 'cadastro-ocorrencia-veiculo-editar',
                component: () => import('./views/cadastros/ocorrencias-veiculos/OcorrenciaEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Ocorrência',
                }
              },


              {
                path: '/backend/monitoramento',
                name: 'backend-monitoramento',
                component: () => import('./views/backend/monitoramento-veiculos/Monitoramento.vue')
              },
              {
                path: '/backend/localizacao',
                name: 'backend-localizacao',
                component: () => import('./views/backend/localizacao-veiculos/Localizacao.vue')
              },
              {
                path: '/backend/listar-rastreamento',
                name: 'backend-listar-rastreamento',
                component: () => import('./views/backend/rastreamento/ListaRastreamentos.vue'),
                meta: {                       
                  pageTitle: 'Listar Rastreamentos',
                }
              },
              {
                path: '/backend/listar-telemetria',
                name: 'backend-listar-telemetria',
                component: () => import('./views/backend/telemetria/ListaTelemetrias.vue'),
                meta: {                       
                  pageTitle: 'Listar Telemetrias',
                }
              },
              {
                path: '/cadastro/acessorios-modulos/pesquisar',
                name: 'cadastro-acessorios-modulos-pesquisar',
                component: () => import('./views/cadastros/acessorios-modulos/AcessorioModulo.vue'),                
                meta: {                       
                  pageTitle: 'Acessórios de Módulo',
                }
              },
              {
                path: '/cadastro/acessorios-modulos/editar',
                name: 'cadastro-acessorios-modulos-editar',
                component: () => import('./views/cadastros/acessorios-modulos/AcessorioModuloEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Acessório de Módulo',
                }
              },
              {
                path: '/cadastro/tecnicos/pesquisar',
                name: 'cadastro-tecnicos-pesquisar',
                component: () => import('./views/cadastros/tecnicos/Tecnico.vue'),                
                meta: {                       
                  pageTitle: 'Técnicos',
                }
              },
              {
                path: '/cadastro/tecnicos/editar',
                name: 'cadastro-tecnicos-editar',
                component: () => import('./views/cadastros/tecnicos/TecnicoEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Técnico',
                }
              },
              {
                path: '/cadastro/usuarios/pesquisar',
                name: 'cadastro-usuarios-pesquisar',
                component: () => import('./views/cadastros/usuarios/Usuario.vue'),                
                meta: {                       
                  pageTitle: 'Usuários',
                }
              },
              {
                path: '/cadastro/usuarios/editar',
                name: 'cadastro-usuarios-editar',
                component: () => import('./views/cadastros/usuarios/UsuarioEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Usuário',
                }
              },
              {
                path: '/cadastro/ordem-servico/pesquisar',
                name: 'cadastro-ordem-servico-pesquisar',
                component: () => import('./views/cadastros/ordem-servico/OrdemServico.vue'),    
                props:true,           
                meta: {                       
                  pageTitle: 'Ordens de Serviço',
                }
              },
              {
                path: '/cadastro/ordem-servico/editar',
                name: 'cadastro-ordem-servico-editar',
                component: () => import('./views/cadastros/ordem-servico/OrdemServicoEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Ordem de Serviço',
                }
              },
              {
                path: '/cadastro/marca-veiculo/pesquisar',
                name: 'cadastro-marca-veiculo-pesquisar',
                component: () => import('./views/cadastros/marca-veiculos/MarcaVeiculo.vue'),    
                props:true,           
                meta: {                       
                  pageTitle: 'Marcas de Veículos',
                }
              },
              {
                path: '/cadastro/marca-veiculo/editar',
                name: 'cadastro-marca-veiculo-editar',
                component: () => import('./views/cadastros/marca-veiculos/MarcaVeiculoEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Marca de Veículo',
                }
              },
              {
                path: '/cadastro/modelo-veiculo/pesquisar',
                name: 'cadastro-modelo-veiculo-pesquisar',
                component: () => import('./views/cadastros/modelo-veiculos/ModeloVeiculo.vue'),    
                props:true,           
                meta: {                       
                  pageTitle: 'Modelos de Veículos',
                }
              },
              {
                path: '/cadastro/modelo-veiculo/editar',
                name: 'cadastro-modelo-veiculo-editar',
                component: () => import('./views/cadastros/modelo-veiculos/ModeloVeiculoEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Modelo de Veículo',
                }
              },

              {
                path: '/cadastro/empresas/pesquisar',
                name: 'cadastro-empresas-pesquisar',
                component: () => import('./views/cadastros/empresas/Empresa.vue'),
                meta: {                       
                  pageTitle: 'Empresas',
                }
              },
              {
                path: '/cadastro/empresas/editar',
                name: 'cadastro-empresas-editar',
                component: () => import('./views/cadastros/empresas/EmpresaEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Empresa',
                }
              },

              {
                path: '/cadastro/tipo-mensagem-teclado/pesquisar',
                name: 'cadastro-tipo-mensagem-teclado-pesquisar',
                component: () => import('./views/cadastros/tipo-mensagem-teclado/TipoMensagemTeclado.vue'),
                meta: {                       
                  pageTitle: 'Tipos Mensagens Teclado',
                }
              },
              {
                path: '/cadastro/tipo-mensagem-teclado/editar',
                name: 'cadastro-tipo-mensagem-teclado-editar',
                component: () => import('./views/cadastros/tipo-mensagem-teclado/TipoMensagemTecladoEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Tipo Mensagem Teclado',
                }
              },

              {
                path: '/cadastro/simcard/pesquisar',
                name: 'cadastro-simcard-pesquisar',
                component: () => import('./views/cadastros/simcards/Simcard.vue'),                
                meta: {                       
                  pageTitle: 'Simcards',
                }
              },
              {
                path: '/cadastro/simcard/editar',
                name: 'cadastro-simcard-editar',
                component: () => import('./views/cadastros/simcards/SimcardEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Simcard',
                }
              },
              {
                path: '/cadastro/simcard/importar',
                name: 'cadastro-simcard-importar',
                component: () => import('./views/cadastros/simcards/ImportarSimcards.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Importar Simcards',
                }
              },
              {
                path: '/relatorios/relatorio-cliente',
                name: 'relatorios-relatorio-cliente',
                component: () => import('./views/relatorios/relatorio-cliente/RelatorioCliente.vue'),                
                meta: {                       
                  pageTitle: 'Relatório de Cliente',
                }
              },
              {
                path: '/relatorios/relatorio-cliente-backup',
                name: 'relatorios-relatorio-cliente-backup',
                component: () => import('./views/relatorios/relatorio-cliente/RelatorioClienteBackup.vue'),                
                meta: {                       
                  pageTitle: 'Relatório de Cliente',
                }
              },
              {
                path: '/comandos/enviar',
                name: 'comandos-enviar',
                component: () => import('./views/components/EnvioComandos.vue'), 
                props: true ,               
                meta: {                       
                  pageTitle: 'Envio de Comandos',
                }
              },
              {
                path: '/backend/desativacao',
                name: 'backend-desativacao',
                component: () => import('./views/backend/ativacao/Desativacao.vue'),
                meta: {                       
                  pageTitle: 'Desativação de Veículo',
                }
              },
              {
                path: '/backend/desativacao-backup',
                name: 'backend-desativacao-backup',
                component: () => import('./views/backend/ativacao/DesativacaoBackup.vue'),
                meta: {                       
                  pageTitle: 'Desativação de Módulo Backup do Veículo',
                }
              },
              {
                path: '/backend/desativacao-acessorios',
                name: 'backend-desativacao-acessorios',
                component: () => import('./views/backend/ativacao/DesativacaoAcessorios.vue'),
                meta: {                       
                  pageTitle: 'Desativação de Acessórios',
                }
              },
              {
                path: '/backend/substituicao',
                name: 'backend-substituicao',
                component: () => import('./views/backend/ativacao/Substituicao.vue'),
                meta: {                       
                  pageTitle: 'Substiutição de Módulo do Veículo',
                }
              },
              {
                path: '/backend/substituicao-simcard',
                name: 'backend-substituicao-simcard',
                component: () => import('./views/backend/ativacao/SubstituicaoSimcard.vue'),
                meta: {                       
                  pageTitle: 'Substituição de Simcard de Módulo do Veículo',
                }
              },
              {
                path: '/backend/ativacao-backup',
                name: 'backend-ativacao-backup',
                component: () => import('./views/backend/ativacao/AtivacaoBackup.vue'),
                meta: {                       
                  pageTitle: 'Ativação de Módulo de Backup',
                }
              },
              {
                path: '/backend/ativacao-acessorio',
                name: 'backend-ativacao-acessorio',
                component: () => import('./views/backend/ativacao/AtivacaoAcessorio.vue'),
                meta: {                       
                  pageTitle: 'Ativação de Acessórios',
                }
              },
              {
                path: '/backend/ativacao',
                name: 'backend-ativacao',
                component: () => import('./views/backend/ativacao/Ativacao.vue'),
                meta: {                       
                  pageTitle: 'Ativação',
                }
              },
              {
                path: '/backend/resultado-ativacao',
                name: 'backend-resultado-ativacao',
                component: () => import('./views/backend/ativacao/ResultadoAtivacao.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Resultado da Ativação',
                }
              },
              {
                path: '/relatorios/relatorio-modulos-com-problema',
                name: 'relatorios-relatorio-modulos-com-problema',
                component: () => import('./views/relatorios/relatorio-modulos-com-problema/RelatorioModulosComProblema.vue'),   
                props: true ,             
                meta: {                       
                  pageTitle: 'Relatório de Módulos com Problema',
                }
              },
              {
                path: '/relatorios/relatorio-modulos',
                name: 'relatorios-relatorio-modulos',
                component: () => import('./views/relatorios/relatorio-modulos/RelatorioModulo.vue'),                
                meta: {                       
                  pageTitle: 'Relatório de Módulo',
                }
              },
              {
                path: '/relatorios/relatorio-valor-mensalidade',
                name: 'relatorios-relatorio-valor-mensalidade',
                component: () => import('./views/relatorios/relatorio-valor-mensalidade/RelatorioValorMensalidade.vue'),                
                meta: {                       
                  pageTitle: 'Relatório do Valor de Mensalidades',
                }
              },
              {
                path: '/relatorios/relatorio-veiculos',
                name: 'relatorios-relatorio-veiculos',
                component: () => import('./views/relatorios/relatorio-veiculos/RelatorioVeiculo.vue'),                
                meta: {                       
                  pageTitle: 'Relatório de Veículos',
                }
              },
              {
                path: '/relatorios/relatorio-veiculos-inativos',
                name: 'relatorios-relatorio-veiculos-inativos',
                component: () => import('./views/relatorios/relatorio-cliente/RelatorioVeiculosInativosCliente.vue'),                
                meta: {                       
                  pageTitle: 'Relatório de Veículos Inativos',
                }
              },
              {
                path: '/relatorios/relatorio-senhas-bloqueio',
                name: 'relatorios-relatorio-senhas-bloqueio',
                component: () => import('./views/relatorios/relatorio-cliente/RelatorioSenhasBloqueio.vue'),                
                meta: {                       
                  pageTitle: 'Relatório Senhas de Bloqueio',
                }
              },             
              {
                path: '/backend/tabela-precos-cliente',
                name: 'backend-tabela-precos-cliente',
                component: () => import('./views/backend/tabela-precos/TabelaPrecosCliente.vue'),                
                meta: {                       
                  pageTitle: 'Tabela de Preços do Cliente',
                }
              },
              {
                path: '/backend/tabela-precos-central',
                name: 'backend-tabela-precos-central',
                component: () => import('./views/backend/tabela-precos/TabelaPrecosCentral.vue'),                
                meta: {                       
                  pageTitle: 'Tabela de Preços Padrão',
                }
              },
              {
                path: '/backend/impressao-contrato',
                name: 'backend-impressao-contrato',
                component: () => import('./views/backend/impressao-contrato/ImpressaoContrato.vue'),                
                meta: {                       
                  pageTitle: 'Impressão de Contrato',
                }
              },
              {
                path: '/backend/impressao-formulario-pedido',
                name: 'backend-impressao-formulario-pedido',
                component: () => import('./views/backend/impressao-formulario-pedido/ImpressaoFormularioPedido.vue'),                
                meta: {                       
                  pageTitle: 'Impressão de Formulário de Pedido',
                }
              },
              {
                path: '/backend/impressao-formulario-pedido-manual',
                name: 'backend-impressao-formulario-pedido-manual',
                component: () => import('./views/backend/impressao-formulario-pedido/ImpressaoFormularioPedidoManual.vue'),                
                meta: {                       
                  pageTitle: 'Impressão de Formulário de Pedido Manual',
                }
              },
              {
                path: '/backend/impressao-formulario-pedido-manual-pre-cadastro',
                name: 'backend-impressao-formulario-pedido-manual-pre-cadastro',
                component: () => import('./views/backend/impressao-formulario-pedido/ImpressaoFormularioPedidoManualPreCadastro.vue'),                
                meta: {                       
                  pageTitle: 'Impressão de Formulário de Pedido Manual Pré-Cadastro',
                }
              },
              {
                path: '/backend/alteracao-veiculo',
                name: 'backend-alteracao-veiculo',
                component: () => import('./views/backend/alteracao-veiculo/AlteracaoVeiculo.vue'),
                meta: {                       
                  pageTitle: 'Alteração de Veículos',
                }
              },
              {
                path: '/backend/alteracao-veiculo-em-lote',
                name: 'backend-alteracao-veiculo-em-lote',
                component: () => import('./views/backend/alteracao-veiculo/AlteracaoVeiculoEmLote.vue'),
                meta: {                       
                  pageTitle: 'Alteração de Veículos em Lote',
                }
              },
              {
                path: '/configuracao/envio-comando',
                name: 'configuracao-envio-comando',
                component: () => import('./views/configuracao/EnvioComandos.vue'),
                meta: {                       
                  pageTitle: 'Envio de Comando Para Módulos',
                }
              },
              {
                path: '/configuracao/status-rastreador',
                name: 'configuracao-status-rastreador',
                component: () => import('./views/configuracao/StatusRastreador.vue'),
                meta: {                       
                  pageTitle: 'Status Módulo',
                }
              },
              {
                path: '/backend/envio-2a-via',
                name: 'backend-envio-2a-via',
                component: () => import('./views/backend/envio-2a-via/Envio2aVia.vue'),                
                meta: {                       
                  pageTitle: 'Envio de Faturas',
                }
              },
              {
                path: '/relatorios/relatorio-comissao',
                name: 'relatorios-relatorio-comissao',
                component: () => import('./views/relatorios/relatorio-comissao/RelatorioComissaoOrdemServico.vue'),                
                meta: {                       
                  pageTitle: 'Relatório Cálculo de Comissões',
                }
              },
              {
                path: '/backend/lote-comando-modulo/pesquisar',
                name: 'backend-lote-comando-modulo-pesquisar',
                component: () => import('./views/backend/lote-comando-modulos/LoteComandoModulos.vue'),    
                props:true,           
                meta: {                       
                  pageTitle: 'Lotes de Comandos para Módulos',
                }
              },
              {
                path: '/backend/lote-comando-modulo/editar',
                name: 'backend-lote-comando-modulo-editar',
                component: () => import('./views/backend/lote-comando-modulos/LoteComandoModulosEdit.vue'),
                props: true ,
                meta: {                       
                  pageTitle: 'Lote de Comandos para Módulos',
                }
              },
              {
                path: '/backend/lote-comando-modulo/incluir',
                name: 'backend-lote-comando-modulo-inserir',
                component: () => import('./views/backend/monitoramento-veiculos/EnvioComandoEmMassa.vue'),                
                meta: {                       
                  pageTitle: 'Envio Comando Em Lote',
                }
              },

              {
                path: '/backend/mancha-urbana',
                name: 'backend-mancha-urbana',
                component: () => import('./views/backend/mancha-urbana/PerimetroEdit.vue'),                
                meta: {                       
                  pageTitle: 'Mancha Urbana do Município',
                }
              },
            ],
        },
        
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router

