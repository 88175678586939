<template>        
    <div id="map" ref="map" class="mapa100porcento"></div>    
</template>

<style scoped>    
    .mapa100porcento {
        width: 100%; 
        height: 100%;                
        margin: 0px; 
        padding: 0px
    }
</style>

<script>
  
  export default {
    props: {
      mapa: {
          type: window.google.maps.Map
      },      
    },
    mounted() {
      this.markers = [];
      this.mapMounted = true;
    },
    created(){     
    },
    data(){        
        return {          
          markers: [],
          mapMounted: false,
        };
    },
    beforeDestroy() {
        this.markers = null;                    
    },
    methods: {
      getMarcadores() {
        return this.markers;
      },     
      // addMarkerWithTitle(location, mapa, titulo) {
      //   var marker = new window.google.maps.Marker({
      //     position: location,
      //     map: mapa,
      //     title: titulo
      //   });
      //   this.markers.push(marker);
      //   return marker;
      // },
      addMarkerWithTitle(location, mapa, titulo, icone) { 
        var marker = new window.google.maps.Marker({
          position: location,
          map: mapa,
          title: titulo,
          icon: icone
        });
        this.markers.push(marker);
        return marker;
      },
      //Add a marker to the map and push to the array.
      addMarker(location, mapa) {
        var marker = new window.google.maps.Marker({
          position: location,
          map: mapa
        });
        this.markers.push(marker);
        return marker;
      }, 
      addMarkerComIcone(location, mapa, icone) {
         var marker = new window.google.maps.Marker({
           position: location,
           map: mapa,
           icon: icone
         });
         this.markers.push(marker);
         return marker;
      },
      // Add a marker to the map and push to the array.
      // addMarkerDraggable(location, mapa) {
      //   var marker = new window.google.maps.Marker({
      //     position: location,
      //     draggable: true,    
      //     map: mapa
      //   });
      //   this.markers.push(marker);
      //   return marker;
      // },
      // Add a marker to the map and push to the array.
      addMarkerDraggable(location, mapa, icone) {
        var marker = new window.google.maps.Marker({
          position: location,
          draggable: true,    
          map: mapa,
          icon: icone
        });
        this.markers.push(marker);
        return marker;
      },
      // Sets the map on all markers in the array.
      setAllMap(mapa) {
        for (var i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(mapa);
        }
      },
      // Removes the markers from the map, but keeps them in the array.
      clearMarkers() {
        this.setAllMap(null);
      },
      // Shows any markers currently in the array.
      showMarkers(mapa) {
        this.setAllMap(mapa);
      },
      // Deletes all markers in the array by removing references to them.
      deleteMarkers() {
        this.clearMarkers();
        this.markers = [];
      },
      getLatLng(lat, lng) {
      return new window.google.maps.LatLng(lat, lng);
      },
      plotCircle(coordenadaCentro, mapa, raio){
          this.deleteMarkers();
          return this.plotCircleNoDelete(coordenadaCentro, mapa, raio);
      },
      plotCircleNoDelete(coordenadaCentro, mapa, raio){
          var raioInicioConfig = {
                      strokeColor: '#FF0000',
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: '#FF0000',
                      fillOpacity: 0.35,
                      map: mapa,
                      center: coordenadaCentro,
                      radius: raio
                  };
          var raioPontoDeVenda = new window.google.maps.Circle(raioInicioConfig);
          this.markers.push(raioPontoDeVenda);
          
          mapa.setCenter(coordenadaCentro);
          mapa.fitBounds(raioPontoDeVenda.getBounds());
          return raioPontoDeVenda;
      },
      ajustarZoom(mapa){
        var bounds = new window.google.maps.LatLngBounds();
        for(var i = 0; i < this.markers.length; i++) {
          bounds.extend(this.markers[i].getPosition());
        }
        mapa.setCenter(bounds.getCenter());
        mapa.fitBounds(bounds);
      },
      deleteMarker(marcador) {
        if (this.markers) {
            var idx = -1;
          for(var i = 0 ; i < this.markers.length; i++) {
            if (this.markers[i] == marcador) {
                idx = i;
            } 
          }
          if (idx != -1) {
            this.markers.splice(idx, 1);
          }
        }
      },         
      desenharPerimetro(mapa) {
        var coordenadasPerimetro = []; 
        var primeiro = null;
        
        for(var i = 0; i < this.markers.length;i++) {
          
          if ( i == 0 && this.markers.length > 2) {
            primeiro = this.markers[i].getPosition();
          }
          
          coordenadasPerimetro.push(this.markers[i].getPosition());
          
        }
        
        if ( primeiro != null ){
          coordenadasPerimetro.push(primeiro);
        }
            
        var perimetroPath = new window.google.maps.Polyline({
            path: coordenadasPerimetro,
            geodesic: true,
            strokeColor: '#FF0000',
            strokeOpacity: 1.0,
            strokeWeight: 2
          });

          perimetroPath.setMap(mapa);
          return perimetroPath;
        }
    }
  }
</script>