/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)


// axios
import axios from "./axios.js"
axios.defaults.withCredentials = true;
Vue.prototype.$http = axios

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import ExibirErrosConcept from './views/components/ExibirErros'
Vue.component('ExibirErrosConcept', ExibirErrosConcept);


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')

import PopUpMotoristas from './views/components/PopUpMotoristas'
Vue.component('PopUpMotoristas', PopUpMotoristas);

import GoogleMapConcept from './views/components/GoogleMap'
Vue.component('GoogleMapConcept', GoogleMapConcept);

import pdf from 'vue-pdf'
Vue.component('pdf', pdf)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
export default { directives: {VueTheMask} }

import FileUpload from 'vue-upload-component'
Vue.use(FileUpload)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
