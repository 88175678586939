<template>
    <vx-card v-if="exibir" class="mb-2" title="Erros encontrados"  card-background="warning" content-color="#fff">        
        <ul>
            <li v-for="error in errorsValidacao" v-bind:key="error" class="text-sm">
                 <feather-icon icon="XCircleIcon" svgClasses="h-4 w-4"></feather-icon>
                 {{ error }}
            </li>
        </ul>
        <template slot="footer">                        
            <vs-button size="small" color="danger" @click="limparErros" >Fechar</vs-button>                    
        </template>
    </vx-card>
</template>

<script>
  
  export default {
    props: {
        errorsValidacao: {
            type: Array,
            required: true  
        }      
    }, 
    data() {
        return {
            exibirErros: true
        }
    },
    computed: {          
        exibir() {
            return ( this.errorsValidacao.length && this.exibirErros);
        }
    },       
    methods: {
        iniciar() {
            this.exibirErros = true;
        },
        limparErros() {
            this.exibirErros = false;
        }
    }
  }
</script>